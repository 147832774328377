.schedule-state-tooltip {
  padding-top: 10px;
  min-width: 140px;
  font-size: 12px;

  .schedule-state-active {
    font-weight: 800;
  }

  .ant-row {
    row-gap: 0px;
  }

  .ant-badge-status-text {
    font-size: 12px;
  }

  .schedule-state-lable-count {
    text-align: center;
  }
}
