.schedule-state-div {
  .card-container {
    padding-left: 70px;
    padding-right: 70px;
  }

  canvas {
    height: 300px !important;
  }

  @media screen and (max-width: 992px) {
    .card-container {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

.delivery-div {
  .card-container {
    padding-left: 35px;
    padding-right: 35px;
  }

  canvas {
    height: 240px !important;
  }

  @media screen and (max-width: 992px) {
    .card-container {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

.actual-capacity-div {
  .card-container {
    padding-left: 35px;
    padding-right: 35px;
  }
  canvas {
    height: 240px !important;
  }

  @media screen and (max-width: 992px) {
    .card-container {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

.carried-report-container {
  height: 100% !important;
  border: none !important;
  padding: 0 0 16px 0 !important;
}
