.iteration-detail-monitor-container {
  .ant-table-content {
    .ant-table-tbody {
      .ant-table-cell {
        padding: 10px 8px !important;
      }
    }
  }
  & thead[class*='ant-table-thead'] {
    & th:nth-child(3),
    th:nth-child(4),
    th:nth-child(5) {
      background-color: #d5ffe9;
    }
  }
  & thead[class*='ant-table-thead'] {
    & th:nth-child(6),
    th:nth-child(7) {
      background-color: #d7e7fd;
    }
  }
  & thead[class*='ant-table-thead'] {
    & th:nth-child(8),
    th:nth-child(9),
    th:nth-child(10),
    th:nth-child(11),
    th:nth-child(12),
    th:nth-child(13) {
      background-color: #fff2d8;
    }
  }
  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 1rem 0;
  }
}
