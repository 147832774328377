$rank1-background-upper: #f3c300;
$rank1-background-lower: #f4c91a;
$rank2-background-upper: #b4bfc8;
$rank2-background-lower: #bcc6ce;
$rank3-background-upper: #d3a88a;
$rank3-background-lower: #d8b196;

.top-performer-card {
  padding: 3%;
  .ant-card-bordered {
    border-radius: 10px;
  }
  .ant-card-body {
    padding: 10px;
  }
  .name {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .ant-avatar {
    width: 110px;
    height: 110px;
    border-radius: 10%;
  }
  .caption-cell {
    vertical-align: top;
    .caption {
      padding-left: 8px;
      font-size: 14px;
      font-style: italic;
    }
  }
}

.rank-1 {
  background-image: linear-gradient(to bottom right, $rank1-background-upper, $rank1-background-lower) !important;
}
.rank-2 {
  background-image: linear-gradient(to bottom right, $rank2-background-upper, $rank2-background-lower) !important;
}
.rank-3 {
  background-image: linear-gradient(to bottom right, $rank3-background-upper, $rank3-background-lower) !important;
}
