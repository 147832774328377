.export-button {
  button {
    color: #1890ff;
    border-color: #1890ff;
    border-radius: 0 !important;
  }
}
.export-menu-items {
  .ant-dropdown-menu-title-content {
    color: #1890ff;
    width: 188px !important;
  }
}
.page-title {
  margin-bottom: 0 !important;
  margin-right: 10px;
  display: inline-block;
}
.team-detail-tab {
  text-align: end;
}
.current-sprint {
  color: rgb(7, 136, 187) !important;
  font-weight: bold !important;
}
.future-sprint {
  color: rgb(185, 185, 185) !important;
}
@media (max-width: 992px) {
  .page-title {
    display: block;
    margin-bottom: 10px !important;
  }
  .team-detail-tab {
    text-align: start;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 409px) {
  .ant-radio-button-wrapper {
    padding: 0 8px !important;
  }
}
