.feature-progress {
  position: absolute;
  top: calc(50% - 10px);
  width: 100%;
  margin: -8px;
  .ant-progress {
    position: absolute;
    padding: 4px;
    .ant-progress-inner {
      padding: 2px;
      border: 1px solid #eaeaea;
      .ant-progress-bg {
        height: 18px !important;
      }
    }
  }
  .feature-progress-percent {
    position: absolute;
    top: calc(50% + 8px);
    width: 100%;
    text-align: center;
    font-size: 13px;
  }
}

.quarter-progress-label {
  display: inline-flex;
  align-items: center;
  margin-right: 0.5rem;
}
.quarter-progress {
  display: inline-flex;
  align-items: center;
  width: 245px;
  .ant-progress {
    padding: 4px;
    .ant-progress-inner {
      padding: 2px;
      border: 1px solid #eaeaea;
      .ant-progress-bg {
        height: 18px !important;
      }
    }
  }
  .quarter-progress-percent {
    width: 100%;
    text-align: center;
    font-size: 13px;
    position: absolute;
    width: 245px;
  }
}
