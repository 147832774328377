$border-color: #d9d9d9;
$border: 0.5px solid;
$section-padding: 1.5em;
$fontSize: 24px;

.organization-chart-overview-section {
  text-align: center;
  .title {
    font-size: $fontSize;
    font-weight: bold;
    color: #0e50a4;
  }
  .switch-btn {
    text-align: left;
    .text-switch {
      color: #0e50a4;
      margin-left: 5px;
    }
  }
  .heading-title {
    padding: 20px 0;
    background: black;
    color: white;
    margin: 30px 0;
    font-size: 24px;
    font-weight: 600;
  }
  .container-overflow {
    overflow-x: auto;
  }
  .filter-section {
    margin-top: 1rem;
  }
  .search-box {
    margin-top: 1rem;
  }
}
