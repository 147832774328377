.btn-add-new-item {
  margin-bottom: 16px;
  margin-right: 10px;
  float: right;
}

.definition {
  .ant-tag {
    padding: 8px 14px;
    border-radius: 20px;
    font-size: 16px;
    white-space: nowrap;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #1890ff;
    color: white;
  }

  .ant-tag:hover {
    cursor: pointer;
    background: #40a9ff;
    border-color: #40a9ff;
  }
}
