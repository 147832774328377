@media only screen and (max-width: 1100px) {
  .page-login {
    .page-login-form-wrapper {
      width: 100%;
      padding: 20px;
    }
  }
}
.page-login {
  height: 100vh;

  background-color: #fff;
  display: flex;
  &__title {
    text-align: center;
    font: normal normal bold 54px/40px Segoe UI;
    color: #003986;
    position: absolute;
    letter-spacing: 0px;
    text-transform: uppercase;
    opacity: 1;
    top: 112px;
    left: 300px;
    height: 72px;
  }

  &__background-image {
    width: 1264px;
    height: 614px;
    position: absolute;
    top: 269px;
    left: 42px;
    opacity: 1;
  }

  &-form-wrapper {
    position: absolute;
    width: 528px;
    right: 0;
    padding: 40px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #fff;
    border-left: 1px solid #ddd;
  }

  &-branding {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 40px;
  }

  &-form-container {
    flex: 3;
    padding: 24px;
    display: flex;
    flex-direction: column;
  }

  & .ant-form-item-control-input input.ant-input {
    padding: 15px;
  }

  & button.ant-btn {
    height: 53px;
  }

  & div.input-password {
    margin-bottom: 5px;
  }
}
