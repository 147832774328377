.ant-dropdown-menu {
  padding: 8px;

  li {
    word-break: break-word;
    white-space: normal;
    padding: 9px 12px;
  }

  & span {
    color: #024fa5;
  }
}
