$color-blue: #0066ff;
$color-yellow: #f3c300;

.page-getting-started {
  height: 100vh;
  display: flex;
  background: #f9c4c8;
  justify-content: center;
  padding-top: 150px;

  .form {
    background-color: white;
    width: 800px;
    height: 520px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    padding-top: 70px;
  }

  label {
    font-weight: bold;
  }

  .ant-form {
    padding-top: 20px;
    width: 650px;
  }

  .submitBtn {
    background: $color-blue;
    border-color: $color-blue;
    color: white;
    margin-top: 30px;
    min-width: 160px;
    min-height: 40px;
  }

  .submitBtnSSO {
    background: $color-yellow;
    border-color: $color-yellow;
    color: $color-blue;
    font-weight: bold;
    margin-top: 30px;
    min-width: 160px;
    min-height: 40px;
  }

  .form-text {
    padding: 20px 80px 20px 80px;
    white-space: pre-wrap;
  }

  .ant-result-title {
    font-weight: bold;
  }

  .link {
    margin-left: 35px;
  }

  .ant-result-subtitle {
    padding: 20px 50px 20px 50px;
    font-size: 16px;
    text-align: left;
    color: black;
  }
  h2.ant-typography {
    font: normal normal bold 30px/40px Segoe UI;
    letter-spacing: 0px;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
    content: '';
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 16px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }

  .ant-result-title {
    font-size: 25px;
  }
}
