.monthly-highlight-title {
  display: grid;
  font-weight: bold;
  margin-bottom: 1rem;
  grid-template-columns: 33% 33% 33%;
  span:nth-of-type(1) {
    grid-column: 2/3;
    align-self: center;
    text-align: center;
  }
  span:nth-of-type(2) {
    grid-column: 3/3;
    align-self: center;
    text-align: right;
  }
}
