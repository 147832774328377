.test-executed-table {
  height: 25px;
  border: 1px solid #d9d9d9;
  padding: 1px;
  background: #d9d9d9;
  border-radius: 0px !important;
  .row-test-executed-state {
    text-align: center;
  }
}
.tooltip-card {
  display: flex;
  justify-content: space-between;
  .bullet-point {
    margin-right: 10px;
    width: 10px;
    height: 10px;
  }
}
.percentage-value.inthebar {
  position: absolute;
}
