.ant-popover-buttons {
  .ant-btn-sm {
    font-size: 15px;
  }
}

.ant-form-item-control-input-content {
  .ant-picker {
    width: 100%;
  }
}

.manage_holiday_datapicker {
  .ant-picker-disabled {
    background: #fff !important;
    border-color: #d9d9d9;
    border: 0px;

    .ant-picker-input {
      input {
        border-color: white;
        color: rgba(0, 0, 0, 0.85);
      }

      .ant-picker-suffix {
        display: none;
      }
    }
  }
}

.ant-form-item-control-input-content {
  .fromDateOfHoliday {
    display: inline-block;
    width: 75%;
  }
  .switch-date {
    display: inline-block;
    margin-left: 5%;
  }
}

.ant-form-item-control-input-content:has(.holiday_name_code) {
  input {
    z-index: 1;
  }
}

.display_code_country {
  position: absolute;
  top: 2px;
  z-index: 2;
  left: 12px;
}
