$border-color: #d9d9d9;
$border: 0.5px solid;
$section-padding: 1.5em;
$fontSize: 20px;
.overview-section {
  .title {
    font-size: $fontSize;
    font-weight: bold;
    color: #0e50a4;
  }
  .view-mode {
    display: inline-block;
    margin-left: 30px;
    .radio-button {
      margin-left: 30px;
      .ant-radio-button-wrapper:first-child {
        border-radius: unset;
      }
      .ant-radio-button-wrapper:last-child {
        border-radius: unset;
      }
    }
  }
  span {
    .ant-select {
      margin-left: 2.2em;
      font-weight: normal;
    }
  }
  padding-bottom: 1.5em;
  .teamgroups-count-section {
    float: right;
    .teamgroups-count-team {
      margin-right: 1em;
      display: flex;
      margin-bottom: 0.5rem;
      .team-count {
        margin-left: 0.3em;
        font-size: $fontSize;
        min-width: 20px;
      }
      .team-icon {
        height: 30px;
      }
    }
  }
}
.iteration-section {
  border-radius: 5px;
  border: $border $border-color;
  padding: $section-padding;
  .title {
    font-size: $fontSize;
    font-weight: bold;
    color: #0e50a4;
  }

  .program-chart-div {
    .card-container {
      padding-left: 0px;
      padding-right: 0px;
    }

    canvas {
      height: 300px !important;
    }

    @media screen and (max-width: 992px) {
      .card-container {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
  .program-utilized-chart-div {
    .card-container {
      padding-left: 0px;
      padding-right: 0px;
    }

    canvas {
      height: 300px !important;
    }

    @media screen and (max-width: 992px) {
      .card-container {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
  .program-schedule-state-chart-div {
    .card-container {
      padding-left: 23px;
      padding-right: 23px;
    }

    canvas {
      height: 300px !important;
    }

    @media screen and (max-width: 992px) {
      .card-container {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
}
.td-filter {
  padding: 5px;
  text-align: left;
}
@media screen and (min-width: 1290px) {
  .responsive-search {
    display: flex;
  }
}
.kanban-search {
  margin-bottom: 20px;
  .ant-select-show-search {
    width: 365px;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input-number {
    border-radius: unset;
    width: 40px;
  }
  .ant-input-number-input {
    padding: 0 5px;
    text-align: right;
  }
  .ant-switch {
    margin-top: 3px;
    margin-left: 30px;
  }
  .kanban-filter {
    display: flex;
    .text {
      margin-left: 10px;
      padding-right: 8px;
    }
    .ant-checkbox-wrapper {
      margin-left: 15px;
    }
  }
  .add-on-affter-input-number {
    background-color: gainsboro;
    padding: 2px 5px 0 5px;
  }
  .utilized-filter {
    display: flex;
  }
}

.custom-scrollbar {
  overflow-y: auto;
  overflow-x: hidden;
  height: 600px;
}

/* width */
div.custom-scrollbar::-webkit-scrollbar,
ul.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
div.custom-scrollbar::-webkit-scrollbar-track,
ul.custom-scrollbar::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 20px;
}

/* Handle */
div.custom-scrollbar::-webkit-scrollbar-thumb,
ul.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #dfdfdf;
  border-radius: 20px;
}

/* Handle on hover */
div.custom-scrollbar::-webkit-scrollbar-thumb:hover,
ul.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #dfdfdf;
}

@media screen and (max-width: 576px) {
  .overview-section {
    span {
      .ant-select {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
}

@media screen and (max-width: 910px) {
  .kanban-filter {
    display: grid !important;
  }
  .ant-switch {
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 654px) {
  .view-mode {
    display: inline-grid !important;
    margin-left: 0 !important;
    .radio-button {
      margin-left: 0 !important;
      .ant-radio-button-wrapper:first-child {
        border-radius: unset;
      }
      .ant-radio-button-wrapper:last-child {
        border-radius: unset;
      }
    }
  }
}
