.integeration-goal-status-tag {
  border-width: 1px;
  border-style: solid;
  width: 100%;
  text-align: center;
  padding: 2px;
  padding: 5px;
  font-size: 16px;
  &.pass {
    background: green !important;
    color: white;
    padding: 5px;
    font-size: 18px;
  }
  &.fail {
    background: red !important;
    color: white;
    padding: 5px;
    font-size: 18px;
  }
}
