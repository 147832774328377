.percent-progress-container {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    position: absolute;
  }
}

.ant-progress.percent-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-progress-outer {
    display: flex;
    align-items: center;
    padding: 3px 2px;
    margin: 0 !important;
    border: 1px solid #8a8a8a;
    .ant-progress-inner {
      .ant-progress-bg {
        height: 16px !important;
      }
    }
  }
}

.card-title.title-overview {
  font-size: 16px;
  text-align: left;
  padding-left: 10px;
}
