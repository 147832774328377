.custom-editor-wrapper {
  .edit-btn {
    position: absolute;
    right: 0;
    top: -49px;
  }
  .action-btn {
    place-content: center;
    margin-top: 16px;
    float: right;
  }
  .cancel-btn {
    margin-right: 10px;
  }
}
