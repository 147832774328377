.app-main-menu {
  .ant-drawer-content {
    .ant-drawer-wrapper-body {
      .ant-drawer-header {
        .ant-drawer-title {
          font-weight: bold;
        }
      }
      .ant-drawer-body {
        padding: 0;
      }
    }
  }
}
.ant-menu-sub.ant-menu-inline {
  padding-left: 10px;
}
@media only screen and (max-width: 1100px) {
  .ant-drawer-body {
    padding: 10px;
  }
  .btn-collapse {
    border: none;
    padding: 5px;
  }
}
