.space-horizontal {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 34px !important;
  width: 100%;
  .ant-card {
    min-width: 390px;
    min-height: 200px;
    max-width: fit-content;
  }
  @media only screen and (max-width: 600px) {
    .ant-space-item {
      width: 100%;
    }
    .ant-card {
      min-width: 100%;
      min-height: 200px;
      max-width: fit-content;
    }
  }
  .create-action-card {
    .ant-card-body {
      margin: 0 auto;
    }

    cursor: pointer;
    box-shadow: none;
    display: flex;
    align-items: center;
    border: 2px #d9d9d9 dashed;
  }
}
