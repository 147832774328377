.sub-section-wrapper {
  .title {
    font: normal normal bold 20px/27px Segoe UI;
    letter-spacing: 0px;
    color: #0e50a4;
    opacity: 1;
    margin: 0;
    padding: 0 0 1rem 0;
  }
  margin-bottom: 2rem;
}
