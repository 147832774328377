.ant-progress-inner {
  border-radius: 0 !important;
}
.title {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  padding-bottom: 20px;
}
.ant-table-thead {
  white-space: nowrap;
}
.container {
  border: 1px solid #d9d9d9;
  padding: 20px 30px 20px 30px;
}
.velocity-card {
  max-width: 18rem;
  background: none;
  padding: 10px 0;
}
.velocity-card-header {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: solid 1px black;
  font-weight: bold;
  font-size: 1rem;
}
.align-left {
  margin-left: 10px;
}
.text-note {
  font-style: italic;
}
