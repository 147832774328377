.btn-add-new-item {
  margin-bottom: 16px;
  margin-right: 10px;
  float: right;
}

.detail-container {
  p:last-child {
    margin-bottom: 0;
  }
}
