.title-section {
  &-add-button {
    border-radius: 0px !important;
  }
  .title-section-add-button {
    text-align: end;

    button {
      font-size: 16px;
      padding: 0;
      height: 35px;
      width: 70px;
      background-color: #2785ff;
      border-radius: 3px;
    }
  }
}
.text-note {
  margin-left: 5px;
  width: -webkit-fill-available;
}
.iteration-goal-status-col {
  min-width: 82px;
}
