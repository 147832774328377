.display-components-modal {
  .item {
    margin-bottom: 20px;
  }
  .ant-modal-title,
  .footer {
    text-align: center;
  }
  .checkbox-group {
    padding-left: 20px;
    width: 100%;
  }
  .divider {
    margin: 8px 0 12px;
  }
  .footer {
    margin-bottom: 0;
  }
  .select-all {
    display: block;
    padding: 0;
  }
  .check-all {
    padding: 10px 0;
  }
  .btn {
    width: 7em;
    font-weight: bold;
    height: 42px;
  }
  .btn-cancel {
    background-color: #eee;
    border: 1px solid #eee;
  }
  .btn-cancel:hover {
    border: 1px solid #f0f0f0;
    color: #595959;
    background-color: #f0f0f0;
  }
  span {
    white-space: normal !important;
  }
}
