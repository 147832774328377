.tags-container {
  margin-top: 20px;

  .ant-tag {
    padding: 8px 14px;
    border-radius: 20px;
    font-size: 16px;
    white-space: nowrap;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-tag:hover {
    cursor: pointer;
    background-color: #2196f3; /* Change background color */
    color: black; /* Change text color */
    box-shadow: 0px 0px 15px rgba(33, 150, 243, 0.5); /* Add stronger box shadow */
  }
}
