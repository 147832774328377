.top-performers-edit {
  table {
    width: 100%;
    thead {
      background: #f6f6f6;
      border: 1px solid #e7e7e7;
      color: #5b5b5b;
      font-size: 13px;
      font-weight: normal;
    }
    tr {
      border: 1px solid #e7e7e7;
    }
  }
  th {
    padding: 5px;
  }
  th:nth-child(1) {
    width: 60px;
  }
  th:nth-child(2) {
    width: 200px;
  }
  th:nth-child(3) {
    width: auto;
  }
  th:nth-child(4) {
    width: 130px;
  }
  th:nth-child(5) {
    width: 100px;
  }
  td {
    vertical-align: middle;
    text-align: center;
    padding: 1%;
    .required-star {
      color: red;
    }
    .edit-caption {
      font-size: 13px;
    }
    .ant-avatar {
      width: 110px;
      height: 110px;
      border-radius: 10%;
      background: lightgray;
    }
    .ant-select {
      width: 100%;
      max-width: 200px;
    }
    textarea {
      height: 150px;
    }
  }
  .action-buttons {
    padding-top: 2.5em;
    text-align: center;
    .btn {
      box-sizing: content-box;
      text-transform: uppercase;
      width: 7em;
      padding: 0.3em 0;
      span {
        font-weight: bold;
      }
      margin: 0 0.5em;
    }
    .btn-cancel:hover {
      border: solid 1px #f0f0f0;
      color: #595959;
      background-color: #f0f0f0;
    }
    .btn-cancel {
      border: solid 1px #eeeeee;
      background-color: #eeeeee;
    }
    text-align: center;
  }
  .validation-text {
    margin-top: 1rem;
  }
}

.ant-upload {
  width: auto;
  height: auto;
  margin: auto;
  background-color: transparent;
  border: none;
}

.img-upload-btn {
  margin-bottom: 0;
}

.performer-delete-btn {
  margin-top: 1px;
  width: 100%;
  button {
    width: 100%;
  }
}
