.editor-wrapper-demo-plan {
  border: 1px solid #d9d9d9;

  .public-DraftStyleDefault-block {
    margin: 0;
  }
}

.editor-wrapper-demo-plan-text {
  .public-DraftStyleDefault-block {
    margin: 0;
  }
}
