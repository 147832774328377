.execution-state-tooltip {
  padding: 10px;
  min-width: 140px;
  font-size: 12px;

  .execution-state-active {
    font-weight: 800;
    margin: 0 !important;
  }

  .display-none {
    display: none;
  }

  .ant-row {
    margin-bottom: 8px;
    row-gap: 0px;
  }

  .ant-badge-status-text {
    font-size: 12px;
  }

  .execution-state-lable-count {
    text-align: center;
  }
}
