.iteration-detail-section {
  .iteration-detail-title {
    padding-bottom: 10px;
    margin-bottom: 1.5em;
    border-bottom: 2px solid #0e50a4;
    .anticon.anticon-info-circle {
      margin-top: -0.5em;
      font-size: 20px;
    }
  }
}
