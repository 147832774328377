.team-report-wrapper {
  .schedule-state-div {
    .card-container {
      padding-left: 70px;
      padding-right: 70px;
    }

    canvas {
      height: 300px !important;
    }

    @media screen and (max-width: 992px) {
      .card-container {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }

  .team-delivery-div {
    .card-container {
      padding-left: 35px;
      padding-right: 35px;
    }

    canvas {
      height: 300px !important;
    }
    .g2-html-annotation {
      overflow: initial !important;
    }

    @media screen and (max-width: 992px) {
      .card-container {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }

  .utilized-capacity-div {
    .card-container {
      padding-left: 35px;
      padding-right: 35px;
    }
    canvas {
      height: 300px !important;
    }

    @media screen and (max-width: 992px) {
      .card-container {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }

  .carried-container {
    height: 100% !important;
    border: none !important;
    padding: 0 0 16px 0 !important;
  }
  .section {
    margin-bottom: 1.5rem;
  }
}
.defect-root-cause-table {
  .ant-table-thead {
    white-space: normal !important;
  }
  .ant-table-cell {
    p {
      margin-bottom: 0;
    }
  }
}
