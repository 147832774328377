.ownership-status-table {
  td {
    font-size: small;
    height: 0px; // This hack allows cell contents to fit using 'height:100%'
  }
  th {
    padding: 8px !important;
  }
}
.status-table-level-cell {
  padding: 0 !important;
  .status-table-level-cell-inner {
    text-align: center;
    height: 100%;
    color: black;
    width: 100%;
    display: flex;
    padding: 8px;
    span {
      margin: auto;
    }
  }
}
.self-evaluation {
  text-align: center;
  font-weight: bold;
  .ant-select-selection-item {
    font-weight: normal;
    white-space: break-spaces;
    line-height: 1.5715 !important;
    font-size: small;
  }
  .ant-select-selector {
    height: fit-content !important;
  }
}
.self-evaluation-cell {
  text-align: center;
  display: grid;
  columns: '45%' '55%';
  font-weight: bold;
  .self-evaluation-cell-left {
    grid-column: 1/2;
    text-align: right;
    padding-right: 10px;
  }
  .self-evaluation-cell-right {
    grid-column: 2/2;
    text-align: left;
  }
}
