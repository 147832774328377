.download-report-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 4px 16px;
  color: #408cff;
  border: 1px solid #408cff;
  .anticon.anticon-mail {
    padding-right: 6px;
  }
}

.create-report-title {
  margin-bottom: 12px;
}

.export-button {
  button {
    color: #1890ff;
    border-color: #1890ff;
    border-radius: 0 !important;
  }
}
.export-menu-items {
  .ant-dropdown-menu-title-content {
    color: #1890ff;
    width: 188px !important;
  }
}

.download-report-modal {
  .item {
    margin-bottom: 20px;
  }
  .ant-modal-title,
  .footer {
    text-align: center;
  }
  .checkbox-group {
    padding-left: 20px;
    width: 100%;
  }
  .divider {
    margin: 8px 0 12px;
  }
  .footer {
    margin-bottom: 0;
  }
  .select-all {
    display: block;
    padding: 0;
  }
  .check-all {
    padding: 10px 0;
  }
  .btn {
    width: 7em;
    font-weight: bold;
    height: 42px;
  }
  .btn-cancel {
    background-color: #eee;
    border: 1px solid #eee;
  }
  .btn-cancel:hover {
    border: 1px solid #f0f0f0;
    color: #595959;
    background-color: #f0f0f0;
  }
  span {
    white-space: normal !important;
  }
}

.checkbox-all-container {
  .ant-checkbox-group {
    padding: 0 16px;
    display: flex;
    flex-flow: row wrap;
    row-gap: 10px;
    .ant-checkbox-wrapper.ant-checkbox-group-item {
      width: 24%;
      span {
        white-space: nowrap;
      }
    }
  }
}
