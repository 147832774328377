.iteration-overview-monitor-container {
  & thead[class*='ant-table-thead'] th:nth-child(3) {
    background-color: #d5ffe9;
  }
  & thead[class*='ant-table-thead'] th:nth-child(4) {
    background-color: #d7e7fd;
  }
  & thead[class*='ant-table-thead'] th:nth-child(5) {
    background-color: #fff2d8;
  }
  .ant-table-content {
    .ant-table-tbody {
      .ant-table-cell {
        padding: 10px 8px !important;
      }
    }
  }

  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 1rem 3rem;
  }
  .tooltip-card {
    display: flex;
    justify-content: space-between;
    padding: 6px 4px;
    .bullet-point {
      margin-right: 6px;
      width: 10px;
      height: 10px;
    }
  }
  .progress-container {
    display: flex;
    align-items: center;
    .ant-progress {
      flex: 0.8;
      .ant-progress-outer {
        display: flex !important;
      }
      .ant-progress-bg {
        height: 26px !important;
      }
    }
    .percentage-value {
      flex: 0.2;
      padding-left: 10px;
      text-align: center;
      white-space: nowrap;
    }
  }
}
.test-executed-table {
  height: 25px;
  border: none;
  padding: 1px;
  background: #d9d9d9;
  border-radius: 0px !important;
  .row-test-executed-state {
    text-align: center;
    .hidden-record {
      // visibility: hidden;
      color: #ffff;
    }
  }
  // &:hover{
  //   .hidden-record{
  //   visibility: initial;
  // }
  // }
}
