@media (max-width: 1600px) {
  .row-multi-progress {
    span {
      display: none;
    }
  }
}

.iteration-detail-title {
  padding-bottom: 10px;
  margin-bottom: 1.5em;
  border-bottom: 2px solid #0e50a4;
  .anticon.anticon-info-circle {
    margin-top: -0.5em;
    font-size: 20px;
  }
}

.iteration-detail-table {
  .ant-table-content {
    .ant-table-tbody {
      .ant-table-cell {
        padding: 10px 8px !important;
      }
    }
  }
}

.progress-container {
  display: flex;
  align-items: center;
  .ant-progress {
    flex: 0.8;
    .ant-progress-outer {
      display: flex !important;
    }
    .ant-progress-bg {
      height: 26px !important;
    }
  }
  .percentage-value {
    flex: 0.2;
    padding-left: 10px;
    text-align: center;
    white-space: nowrap;
  }
}

.multi-progress-container {
  height: 26px;
  background: #d9d9d9;
  border-radius: 0px !important;
  overflow: hidden;
  .row-multi-progress {
    height: 100%;
    display: flex;
    div {
      text-align: center;
      padding: 0 !important;
    }
  }
}

.tooltip-card {
  display: flex;
  justify-content: space-between;
  padding: 6px 4px;
  .bullet-point {
    margin-right: 6px;
    width: 10px;
    height: 10px;
  }
}

#iteration-overview-table {
  th:nth-child(1),
  th:nth-child(2) {
    background-color: #f9f9f9;
  }
  th:nth-child(3) {
    background-color: #ffd4d4;
  }
  th:nth-child(4) {
    background-color: #d5ffe9;
  }
  th:nth-child(5) {
    background-color: #d7e7fd;
  }
  th:nth-child(6) {
    background-color: #fff2d8;
  }
}

#iteration-detail-table {
  th:nth-child(1),
  th:nth-child(2) {
    background-color: #f9f9f9;
  }
  th:nth-child(3),
  th:nth-child(4) {
    background-color: #ffd4d4;
  }
  th:nth-child(5),
  th:nth-child(6),
  th:nth-child(7) {
    background-color: #d5ffe9;
  }
  th:nth-child(8),
  th:nth-child(9) {
    background-color: #d7e7fd;
  }
  th:nth-child(10),
  th:nth-child(11),
  th:nth-child(12),
  th:nth-child(13),
  th:nth-child(14),
  th:nth-child(15) {
    background-color: #fff2d8;
  }
  th:nth-child(3),
  th:nth-child(4),
  th:nth-child(5),
  th:nth-child(6),
  th:nth-child(7),
  th:nth-child(8),
  th:nth-child(9) {
    white-space: pre-wrap;
  }
}
