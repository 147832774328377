@import 'theme/color.scss';
body {
  #root {
    font-family: 'Segoe UI';
  }
  .ant-table-thead > tr > th {
    font-weight: bold;
  }
}
.k-gauge {
  .k-arcgauge-label {
    width: 100%;
  }
}

.section-title-container {
  padding-bottom: 16px;
}

.section-title {
  display: inline-block;
  margin-right: 24px;
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  color: $color-primary;
}

.download-button {
  margin-left: 24px;
}

.card-title {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 12px;
}

.ant-space-item {
  display: flex;
}

.app-container {
  padding: 0 16px;
  min-height: calc(93.7vh - 70px);

  display: flex;
  flex-flow: column;

  .content {
    flex: 1 1 auto;
    background-color: #fff;
    padding: 2rem;
  }

  .ant-btn {
    border-radius: 5px;
  }

  .page-manage,
  .page-manage-request {
    .action-button,
    .request-action-button {
      text-align: right;
      margin-bottom: 24px;
    }

    .save-action {
      margin-left: 15px;
    }

    .ant-card {
      .ant-card-body {
        .ant-form {
          .ant-row .request-form {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }

  .page-container {
    .section-container {
      // box-shadow: 0px 0px 5px 5px #d6d6d6;
      padding: 24px 32px;
      background-color: #fff;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
}

.page-footer {
  text-align: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.logo-holder {
  height: 100%;
}

.edit-mode-session {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  .button-format {
    border-radius: 5px;
  }
}

.ant-card-bordered {
  box-shadow: 4.7px 1.9px 12.1px rgba(0, 0, 0, 0.014), 8.3px 3.4px 20.4px rgba(0, 0, 0, 0.02),
    11.9px 4.9px 27.3px rgba(0, 0, 0, 0.025), 16.3px 6.8px 34.8px rgba(0, 0, 0, 0.03),
    23.2px 9.6px 46.5px rgba(0, 0, 0, 0.036), 41px 17px 80px rgba(0, 0, 0, 0.05);
}

.hidden {
  display: none !important;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-dir-col {
  flex-direction: column;
}

.ant-menu-item {
  padding-left: 15px;
}

.ant-page-header {
  padding: 12px 24px !important;
}

@media only screen and (max-width: 1100px) {
  .left-menu-has-sider {
    display: none;
  }
  .app-container {
    padding: 10px 10px 0 10px;
  }
  .page-home .page-container {
    .section-container {
      padding: 12px 16px;
    }
  }
  .ant-page-header {
    padding: 8px 12px !important;
  }
}
@media only screen and (max-width: 550px) {
  .page-home .page-container {
    .section-container {
      padding: 8px 10px !important;
      .ant-card-body {
        padding: 0px !important;
        .card-item-image {
          .ant-image {
            height: 200px !important;
          }
        }
        .card-item-top3 {
          padding-bottom: 20px !important;
        }
      }
    }
  }
  .ant-card-body {
    padding: 12px !important;
  }
  .ant-page-header-content {
    display: flex;
    flex-wrap: wrap;
  }
  .logo-holder {
    gap: 0px !important;
  }
  .site-layout {
    .ant-layout-content {
      padding: 0 !important;
    }
  }
}

.ant-table-cell {
  word-break: break-word;
}

.hide-element {
  display: none !important;
}

.show-element {
  display: block !important;
}

.card-container {
  border: 1px solid rgb(238, 238, 238);
  padding: 16px;
  margin-bottom: 16px;
}

.tooltip-content {
  margin-bottom: 0;
  padding: 10px 25px 0px 25px;
}

@media only screen and (max-width: 768px) {
  .ant-breadcrumb {
    margin-bottom: 40px !important;
  }
}
