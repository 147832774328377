#release-train-detail-table {
  th:nth-child(3),
  th:nth-child(4) {
    background: #d5ffe9;
  }
  th:nth-child(5),
  th:nth-child(6) {
    background: #d7e7fd;
  }
  th:nth-child(7),
  th:nth-child(8),
  th:nth-child(9),
  th:nth-child(10) {
    background: #fff2d8;
  }
  th:nth-child(3),
  th:nth-child(4),
  th:nth-child(5),
  th:nth-child(6) {
    white-space: pre-wrap;
  }
}
