.change-password-modal.ant-modal {
  .ant-modal-content {
    border-radius: 8px;
    .ant-modal-header {
      .ant-modal-title {
        font-size: normal normal bold 25px/24px Segoe UI;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        font-size: x-large;
      }
    }
    .ant-modal-body {
      .ant-row .ant-form-item {
        margin-bottom: 20px;
      }
    }
  }
}
