.form-footer {
  text-align: center;
  .ant-btn {
    margin: 0px 15px;
    width: 100px;
  }
}
.custom-report-form-img {
  margin-right: 15px;
}
.editor-box-read-only {
  .public-DraftStyleDefault-block {
    margin: 0;
  }
}
.editor-box {
  padding: 5px 20px;
  min-height: 100px;
}
.editor-wrapper {
  border: 1px solid #d9d9d9;
}
.ant-form-item-label {
  font-size: normal normal normal 16px/21px Segoe UI;
  text-align: left;
  label {
    flex-direction: row-reverse;
  }
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    margin-left: 0.5em;
  }
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: none;
  content: '';
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f;
  font-size: 16px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.ant-form-item-label > label::after {
  display: none;
  content: '';
}
