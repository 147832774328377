.action-card {
  box-shadow: none;
  .ant-card-body {
    display: flow-root;
    // flex-direction: column;
    flex-wrap: wrap;
    justify-content: start;
    height: 100%;
    padding: 14px 10px 5px 20px;
    .action-content {
      font-size: 16px;
      min-height: calc(100% - 35px);
      padding: 0;
      width: 100%;
      max-height: 485px;
    }
    .previous-iteration {
      min-height: calc(100% - 60px);
    }
    .footer-container {
      &.footer-preAction {
        width: 100%;
      }
      width: 80%;
    }
    .actions-container {
      width: 100%;
      align-items: flex-start;
      .ant-space-item {
        width: 100%;
        .ant-row {
          width: 100%;
        }
      }
      .btn-cancel {
        color: #b1b1b1;
      }
      .btn-mark-done {
        padding: 0;
        color: #0066ff;
      }
      .un-editing-container {
        &.action-container {
          display: block;
        }
        .status-button {
          width: 100%;
          display: flex;
          justify-content: center;
        }
        .user {
          font-style: italic;
          color: #b1b1b1;
        }
        .btn-done {
          padding: 0;
          .text {
            height: 100%;
            padding-left: 8px;
            color: #0ba800;
          }
        }

        width: 100%;
        justify-content: flex-start;
      }
      .edit-trash {
        display: flex;
      }
    }
  }
}
.miss-deadline {
  color: red !important;
}
.readonly-card {
  box-shadow: none !important;
  width: 388px;
  .ant-card-body {
    padding: 24px;
    background-color: white;
  }
}
div.text-footer {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
