.report {
  &-title {
    text-align: center;
    position: inherit;
    display: block;
    letter-spacing: 0px;
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}
