.card {
  background: #fff8db 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 1.5em 1.5em;
  padding-bottom: 12px;
  .card-title {
    text-align: left;
    letter-spacing: 0px;
    font-size: 20px;
    color: #000000;
    opacity: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .card-actions {
    padding-top: 0.25em;
    .edit {
      float: right;
      text-align: left;
      font: normal normal normal 16px/25px Segoe UI;
      letter-spacing: 0px;
      color: #0066ff;
      opacity: 1;
    }
    .edit:hover {
      text-decoration: underline;
    }
    .delete {
      margin-left: 0.5em;
      margin-top: 0.25em;
      color: #707070;
    }
    .edit:hover {
      cursor: pointer;
    }
  }

  .card-message-container {
    min-height: 109px;
  }

  .card-message {
    min-height: 84px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-align: left;
    font: normal normal normal 16px/21px Segoe UI;
    letter-spacing: 0px;
    box-sizing: border-box;
    color: #000000;
  }
  .card-from {
    text-align: right;
    font: italic normal normal 16px/21px Segoe UI;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
  }
  .card-images {
    display: block;
    color: #0066ff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .card-images:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  .readmore-btn {
    float: right;
    // display: block;
    font: normal normal normal 16px/25px Segoe UI;
    color: #0066ff;
    cursor: pointer;
  }
}
