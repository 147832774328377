.section-common-wrapper {
  margin: 24px 0;
  .section-header {
    margin-bottom: 1.2em;
    border-bottom: 2px solid #09488a;
    opacity: 1;
    .section-title-container {
      align-items: center;
      display: flex;
      .section-subtitle-common {
        display: flex;
        letter-spacing: 0px;
        font: normal normal bold 20px/27px Segoe UI;
      }
      .section-title-common {
        margin: 0px;
        display: flex;
        text-align: left;
        font: normal normal bold 20px/27px Segoe UI;
        letter-spacing: 0px;
        color: #09488a;
      }
    }
  }
  .portfolio-section {
    margin: 2rem 0;
  }
}
