.sync-info-container {
  position: absolute;
  right: 46px;
  top: 81px;
  span {
    font-style: italic;
    color: #bbbbbb;
  }
}
@media only screen and (max-width: 768px) {
  .sync-info-container {
    top: 112px;
  }
}
