.guideline-faq-wrapper {
  .ant-tabs-nav {
    place-self: center;
  }
  .ant-tabs-nav::before {
    border-bottom: 0 !important;
  }
  .ant-tabs-tab-active {
    background-color: #1890ff;
    border-radius: 4px;
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    background-color: #1890ff;
    color: #fff !important;
  }
  .ant-tabs-tab {
    padding: 3px 30px;
    border-radius: 2px;
    border: 1px solid #ccc;
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin-left: -1px !important;
  }
  .tab-item {
    position: relative;
  }
}
