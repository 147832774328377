.top-performers-section {
  margin-bottom: 24px;
  .top-performers-header {
    text-transform: uppercase;
    margin-bottom: 1.2em;
    border-bottom: 2px solid #0e50a4;
    opacity: 1;
    padding-bottom: 0.5rem;
    .top-performers-title {
      text-align: left;
      font: normal normal bold 20px/27px Segoe UI;
      letter-spacing: 0px;
      color: #0e50a4;
      opacity: 1;
      margin-bottom: 1rem;
    }
    .button-edit {
      float: right;
      margin-left: auto;
      text-align: right;
      background: #0066ff 0% 0% no-repeat padding-box;
      color: #fff;
      opacity: 1;
      position: relative;
      border-radius: 0% !important;
      letter-spacing: 1px;
      span {
        margin: 0 0.3em;
        text-align: center;
      }
    }
  }
}
.top-performers-modal.ant-modal {
  width: 75% !important;
  .ant-modal-content {
    .ant-modal-header {
      .ant-modal-title {
        font-size: normal normal bold 25px/24px Segoe UI;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        font-size: x-large;
      }
    }
    .ant-modal-body {
      padding: 3em 3em;
      .form {
        .ant-row {
          margin-bottom: 0;
          min-height: 58px;
        }
        .message-section {
          height: 154px;
          #message {
            word-break: break-word;
            max-height: 8em;
            min-height: 8em;
          }
        }
        .ant-form-item-label {
          font-size: normal normal normal 16px/21px Segoe UI;
          text-align: left;
          > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
            margin-left: 0.5em;
          }
        }
        .ant-form-item-control {
          font-size: normal normal normal 16px/21px Segoe UI;
          .ant-form-item-control-input {
            .ant-input-textarea.read-only > textarea {
              background-color: #d9d9d9;
            }
          }
        }
        .ant-form-item-label {
          label {
            flex-direction: row-reverse;
          }
        }
        .btn-upload {
          font-size: normal normal normal 16px/25px Segoe UI;
        }
      }
    }
    .ant-modal-footer {
      text-align: center;
    }
  }
}
