.anticon.anticon-caret-up {
  svg {
    margin-bottom: -2px;
  }
}

.percent-icon {
  display: inline-block;
  position: absolute;
  padding: 22px 18px;
}

.percent-icon.top {
  right: 0;
  top: 0;
}

.percent-icon.bottom {
  bottom: 16px;
  right: 16px;
}
