.ranking-image-container {
  text-align: center;
  padding: 15px;
  .ant-image {
    max-width: 30%;
  }
}

.top-row {
  .card-container {
    height: 100%;
  }
}

.tail-header {
  :hover {
    cursor: default;
  }
}
