.report-velocity {
  border: 1px solid rgb(238, 238, 238);
  padding: 13px;
  margin-bottom: 16px;
  .velocity-title {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    padding-bottom: 20px;
  }
  .ant-row.body-report {
    margin-top: 1rem;
  }
}
.g2-tooltip-title {
  margin-bottom: 12px;
  margin-top: 12px;
}
.g2-tooltip-list {
  margin: 0px;
  list-style-type: none;
  padding: 0px;
  max-width: 250px;
}
.g2-tooltip-list-item {
  list-style-type: none;
  padding: 0px;
  margin: 12px 0px;
}
.g2-tooltip-marker {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}
.g2-tooltip-value {
  display: inline-block;
  float: right;
  margin-left: 30px;
}
