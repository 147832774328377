.throughput {
  position: absolute;
  width: 100%;
}
.thoughtput-filter {
  margin-bottom: 12px;
  width: 120;
}
.select-filter {
  width: 25%;
}
