.custom-report-card {
  border: 1px solid #eeeeee;
  padding: 1.5em 1.5em;
  padding-bottom: 12px;
  .card-title {
    text-align: center;
    letter-spacing: 0px;
    font-size: 20px;
    color: #000000;
    opacity: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: auto;
    display: flex;
    justify-content: space-between;
    .title {
      width: 90%;
      white-space: normal;
    }
  }
  .card-actions {
    .edit {
      float: right;
      text-align: left;
      font: normal normal normal 16px/25px Segoe UI;
      letter-spacing: 0px;
      color: #0066ff;
      opacity: 1;
    }
    .edit:hover {
      text-decoration: underline;
    }
    .delete {
      margin-left: 0.5em;
      margin-top: 0.25em;
      color: #707070;
    }
    .edit:hover {
      cursor: pointer;
    }
  }
  .note {
    font-weight: bolder;
  }
  .note-detail {
    overflow-wrap: break-word;
  }
  img {
    max-width: 100%;
    object-fit: cover;
    width: 100%;
    padding-right: 30px;
    padding-bottom: 10px;
  }
  .editor-wrapper {
    border: 0;
  }
  .editor-box {
    padding: 0;
  }
  .public-DraftStyleDefault-block {
    margin: 0;
  }
  .progress-chart {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
  }
  .content-text {
    font-size: 50px;
    font-weight: 600;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
