.work-item {
  thead th.ant-table-cell {
    font-weight: bold;
  }
  .ant-tag {
    width: 23px;
    align-items: center;
    text-align: center;
    font-weight: bold;
    margin-right: 4px;
  }
  .tag-wrapper {
    white-space: nowrap;
    .ant-tag span {
      opacity: 0;
    }
    .ant-tag.is-active span {
      opacity: 1;
    }
  }
  .highlight-effort {
    border-radius: 50%;
    color: #fff;
    width: 50%;
    height: 2.5rem;
    text-align: center;

    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .highlight-effort.not-update-effort {
    background-color: #fd0000 !important;
  }
  .highlight-effort.effort-decrease {
    background-color: #dfb300 !important;
  }
  .feature-status {
    white-space: nowrap;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-style: italic;
  }
}

.breakline-cell {
  white-space: break-spaces;
}
