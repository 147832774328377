.issue-report-status {
  border-width: 1px;
  border-style: solid;
  width: 100%;
  text-align: center;
  padding: 2px;
  padding: 5px;
  font-size: 18px;
  &.todo {
    background: #f8f8f8 !important;
    border: 1px solid #aeaeae !important;
    color: #aeaeae;
    padding: 5px;
    font-size: 15px;
    font-weight: 500;
  }
  &.confirmed {
    background: #f6ffed !important;
    border: 1px solid rgb(17, 172, 17) !important;
    color: rgb(17, 172, 17) !important;
    padding: 5px;
    font-size: 15px;
    font-weight: 500;
  }
  &.inprogress {
    background: #fffce4 !important;
    border: 1px solid #c5b105 !important;
    color: #c5b105;
    padding: 5px;
    font-size: 15px;
    font-weight: 500;
  }
  &.done {
    background: #ecf8ff !important;
    border: 1px solid #2db7f5 !important;
    color: #2db7f5;
    padding: 5px;
    font-size: 15px;
    font-weight: 500;
  }
  &.reject {
    background: #fff1f0 !important;
    border: 1px solid red !important;
    color: red;
    padding: 5px;
    font-size: 15px;
    font-weight: 500;
  }
  &.duplicate {
    background: #fff4f4 !important;
    border: 1px solid #ef65c1 !important;
    color: #ef65c1;
    padding: 5px;
    font-size: 15px;
    font-weight: 500;
  }
  &.reopen {
    background: #f8f8f8 !important;
    border: 1px solid #aeaeae !important;
    color: #aeaeae;
    padding: 5px;
    font-size: 15px;
    font-weight: 500;
  }
}

.btn-delete:hover {
  text-decoration: underline;
}
