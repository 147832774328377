#release-train-overview-table {
  th:nth-child(3) {
    background: #d5ffe9;
  }
  th:nth-child(4) {
    background: #d7e7fd;
  }
  th:nth-child(5) {
    background: #fff2d8;
  }
}

.tooltip-card-overview-table {
  background: #d9d9d9;
  .tooltip-card-item {
    display: flex;
    justify-content: space-between;
    background: white;
    padding: 5px 0;
    .bullet-point {
      margin-right: 10px;
      width: 10px;
      height: 10px;
    }
  }
  .row-cards-item {
    text-align: center;
    height: 26px;
  }
}
